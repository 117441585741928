@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
* {
      font-family: 'Indie Flower';
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  background-color: #FFFDF5;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

header > img {
  width: 70px;
}

.address{
  font-family: "Helvetica Neue", "Helvetica";
  display: block;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5em 1em;
  margin-top: 0.7em;
  color: rgb(70, 81, 156);
  border-radius: 5px;
  font-weight: 900;
  font-size: 20px;
  border: 1px solid rgb(20, 21, 66);
}

.fox {
  width: 15%;
  vertical-align: middle;
  padding-right: 7px;
}

.balance {
  font-family: "Helvetica Neue", "Helvetica";
  display: block;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5em 1em;
  margin-top: 0.7em;
  color: rgb(70, 81, 156);
  border-radius: 5px;
  font-weight: 900;
  font-size: 20px;
  border: 1px solid rgb(20, 21, 66);
}


h1 {
  font-size: 70px;
  text-align: center;
  padding: 0 1em;
  color: rgb(47, 47, 47);
}

.centerer {
  margin: 0 auto;
  text-align: center;
}

button {
  width: 300px;
  padding: 1px 6px 1px 6px;
  line-height: 24px;
  height: 60px;
  border-radius: 10px;
  border: none;
  background-color: #1e1e1e;
  color: white;
  font-size: 25px;
}


#disabled {
    background-color: #8e8e8e;
    color: rgb(210, 210, 210);
}

#disabled:hover {
  cursor: default;
  border: none;
}

button:hover {
  background-color: #FFD976;
  cursor: pointer;
  color: rgb(0, 0, 0);
  border: 2px solid black;
}

figure {
  width: 200px;
  margin: 0 auto;
}


.cat_dancer {
  position: relative;
  top: 27px;
  width: 80%;
}

.logo:hover {
  cursor: pointer;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.tooltip {
  /* height: 100px; */
}

.logo {
  height: 60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #FFD976;
  color: #000;
  border: 1px solid black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 400px;
  top: 1%;
  left: 50%;
  margin-left: -200px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

p {
  padding: 0 3em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 2em;
  }
  button {
    font-size: 15px;
    width: 250px;
  }
  header {
    display: block;
  }
  .address {
    display: none;
  }
}
